import React from 'react'
import './Bg.css'

const Bg = () => {
  return (
    <div className='bg'>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
    </div>
  )
}

export default Bg